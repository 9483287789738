<template>
  <div>
    <div class="alert-row">
      <Alert v-if="loaded" />
    </div>
    <div class="fond-bleu"></div>
    <div class="row welcome-row welcome-banner">
      <h1 class="mb-0 mt-2 fs-b">
        Bienvenue<span class="sr-only">sur votre tableau de bord</span>
      </h1>
    </div>
    <div class="fr-grid-row mt-2">
      <span class="mx-w-750"
        ><Declaration
          v-if="chargementCodeAffichages && chargement"
          :declarationData="declarationData"
          :infoService="infoService"
      /></span>
      <span class="p-lf-2 mx-w-432" v-if="!isMobileDevice"><Messages /></span>
    </div>
    <EtatPaiements v-if="chargementCodeAffichages" />
    <EchancierRe
      v-if="chargementCodeAffichages && chargement"
      :declarationData="declarationData"
      :infoService="infoService"
    />
    <CertificationProconnectModal
      v-if="showModalCerti"
      @close="closeAll()"
      @closeAndOpen="closeModalProconnect()"
    />
    <CertificationProconnectSirenInconnuModal
      v-if="showModalCertifSirenInconnu"
      @close="closeModalSirenInconnu()"
    />
  </div>
</template>

<script>
import EtatPaiements from '../components/WidgetEtatPaiements.vue';
import Declaration from '../components/WidgetDeclaration';
import Messages from '../components/WidgetMessagerie';
import EchancierRe from '../components/WidgetEcheancierRefonte';
import { mapActions, mapGetters } from 'vuex';

import CertificationProconnectModal from '../components/CertificationProconnectModal';
import CertificationProconnectSirenInconnuModal from '../components/CertificationProconnectSirenInconnuModal';
import Alert from '../shared/Alert';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { sendTag, isMobileDevice } from '../utils/methodsUtils';

import { getTdbDeclarations } from '../services/DeclarationService';
import {
  technical,
  gererErreur,
  GENERIC_ERROR_MESSAGE
} from '../utils/erreurUtils';

export default {
  components: {
    Declaration,
    EtatPaiements,
    Messages,
    EchancierRe,
    CertificationProconnectModal,
    CertificationProconnectSirenInconnuModal,
    Alert
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      showModalCerti: false,
      showModalCertifSirenInconnu: false,
      declarationData: {},
      chargement: false,
      infoService: false, //check si on a des erreurs dans la réponse du service de declarations tdb
      dataLoaded: false
    };
  },
  computed: {
    ...mapGetters([
      'isFormDirigeant',
      'isMonoSiren',
      'indicateursKO',
      'chargementCodeAffichages',
      'codeAffichages',
      'siren',
      'etablissements'
    ]),
    loaded() {
      return this.dataLoaded;
    },
    isMobileDevice() {
      return isMobileDevice();
    }
  },
  methods: {
    async verificationProConnect() {
      const isProConnectKO = localStorage.getItem('isProConnectKO');
      const isProConnectKOTdb = localStorage.getItem('isProConnectKOTdb');
      if (isProConnectKO === 'true' && isProConnectKOTdb === 'true') {
        const etablissements = this.etablissements;
        if (etablissements.length <= 5) {
          const messageErreur =
            "<p class='fs-875'>Vous n'êtes plus considéré comme dirigeant de cette entreprise par ProConnect. </br> Nous vous retirons le rôle AGE ainsi que les attributions liées à ce rôle.</p>";
          this.toggleErreurConfirmation(messageErreur);
        }
        localStorage.setItem('isProConnectKOTdb', false);
      }
    },
    sendTagTbd() {
      sendTag('tableau_de_bord', '', '', '');
    },

    toggleErreurConfirmation(message) {
      this.setAGE(false);
      this.setContent({ html: message, action: 'info' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'ok' || payload === 'hide') {
          this.$router.push({ path: this.pathParent }).catch(() => {});
        }
      });
    },
    showModalProconnect() {
      this.showModalCerti = true;
      this.closeModalSirenInconnu();
    },
    closeModalProconnect() {
      this.showModalCerti = false;
      this.showModalSirenInconnu();
    },
    showModalSirenInconnu() {
      this.showModalCertifSirenInconnu = true;
    },
    closeModalSirenInconnu() {
      this.showModalCertifSirenInconnu = false;
    },
    closeAll() {
      this.showModalCerti = false;
      this.showModalCertifSirenInconnu = false;
    },

    ...mapActions([
      'showPopup',
      'setContent',
      'setAGE',
      'fetchCodeAffichages',
      'fetchIndicateurs'
    ])
  },
  async mounted() {
    //Appeler la méthode pour envoyer le hit Eulerian
    this.sendTagTbd();
    // Dans le cas monosiren on ne passe pas par le selecteur de siren (cas multisiren) et le mutateur du sirent dans le store n'est pas activé
    // Du coup l'appel vers les codes affichages et indicateurs et on est obligé de le forcer  donc.
    if (this.isMonoSiren) {
      await this.fetchCodeAffichages(this.$store.state.compte.siren);
      await this.fetchIndicateurs(this.$store.state.compte.siren);
    }
    await this.verificationProConnect();
    // IMPORTANT : this.codeAffichages['bloc-declarations'] est calculé depuis un autre appel asynchrone (checkAll) du composant WidgetMessagerie
    // Il faut attendre que cette valeur soit calculer avant d'afficher ce composant (WidgetDeclaration) et c'est faisable au niveau de son parent (voir chargementCodeAffichages dans TableauDeBord.vue)

    try {
      const data = await getTdbDeclarations(this.$store.state.compte.siren);

      this.declarationData = data ? data : {}; // anticiper un null
      this.chargement = true;

      //gérer les erreurs après
      const errInfo = data.infos;

      if (errInfo && errInfo.length > 0) {
        errInfo.forEach((e) => {
          gererErreur({
            source: 'tdb',
            messages: e.infoMessage,
            id: 'err-back-tdb-declarations',
            infoType: e.infoType,
            infoCode: e.infoCode
          });
        });
        this.infoService = true;
      }
    } catch (err) {
      gererErreur({
        source: 'tdb',
        messages: GENERIC_ERROR_MESSAGE,
        id: 'err-front-tdb-declarations',
        infoType: technical
      });
      this.chargement = true;
    }

    this.dataLoaded = true;
  }
};
</script>

<style lang="scss" scoped>
.alert-row {
  padding-bottom: 30px;
  margin-top: 40px;
}

.welcome-row {
  align-items: center;
}

.welcome-banner {
  height: 74px;

  h1 {
    color: $base-color;
    line-height: 25px;
    font-size: 3.215rem;
    margin-left: 12px;
    width: auto;
  }
}
.fond-bleu {
  background-color: #f2f2f9;
  height: 28.313rem;
  width: 82.8rem;
  position: absolute;
  left: -2.5rem;
  z-index: -1;
  @media (min-width: 1400px) {
    width: 102%;
  }
}
.p-lf-2 {
  padding-left: 2rem;
}
.mx-w-432 {
  max-width: 432px;
}
.mx-w-750 {
  max-width: 750px;
}
@media only screen and (max-width: 760px) {
  .fond-bleu {
    width: 100%;
    left: 0;
  }
}
</style>
