<template>
  <div>
    <div class="d-flex w-100 align-items-center" style="position: relative">
      <div class="circle">
        <p class="montant-header">Montant total</p>
        <p class="montant-value">
          <span class="sr-only"> {{ $filters.numberSpacedRgaa(total) }}</span>
          <span aria-hidden="true"> {{ $filters.numberSpaced(total) }}</span>
        </p>
      </div>
      <!-- PCR-5804 : Critère 1.2 Chaque image de décoration est-elle correctement ignorée par les technologies d'assistance ? -->
      <svg
        width="100%"
        viewBox="0 0 500 500"
        ref="counter"
        style="height: 282px"
        id="paiement-counter"
        aria-hidden="true"
      >
        <defs>
          <filter id="f1">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <circle
          cx="250"
          cy="250"
          r="155"
          fill="white"
          stroke-opacity="0.5"
          style="stroke: gray; stroke-width: 1px; opacity: 0.1"
          filter="url(#f1)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    informations: Array
  },

  mounted() {
    let montantEnCours = this.montantRestantAPayerCumulEnCours();
    let montantAPayer = this.montantRestantAPayerCumul();
    let montantEnRetard = this.montantRestantAPayerCumulAPayerEnRetard();
    this.drawIt(
      this.$refs.counter,
      this.total,
      montantEnCours,
      montantAPayer,
      montantEnRetard
    );
  },
  methods: {
    montantRestantAPayerCumulEnCours() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'EN_COURS_DE_TRAITEMENT'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    },
    montantRestantAPayerCumul() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'A_PAYER'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    },
    montantRestantAPayerCumulAPayerEnRetard() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'A_REGULARISER'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    },

    polarToCartesian(radius, angleInDegrees) {
      /** Calculer l'angle en radians */
      const angleInRadians = (angleInDegrees * Math.PI) / 180.0;

      return {
        x: 250 /** svgSize/2 */ + radius * Math.cos(angleInRadians),
        y: 250 /** svgSize/2 */ - radius * Math.sin(angleInRadians)
      };
    },
    /** Déterminer les points de l'arc en fonction du rayon, angle de départ et angle d'arrivée */
    describeArc(radius, startAngle, endAngle) {
      const start = this.polarToCartesian(radius, startAngle);
      const end = this.polarToCartesian(radius, endAngle);

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
      ].join(' ');

      return d;
    },
    drawIt(el, total, montantEnCours, montantAPayer, montantEnRetard) {
      let degree1, degree2, degree3; //degrés des montants
      let point2, point3; // points en plus pour les logos
      let path1, path2, path3; // différents chemins des montants

      if (montantEnCours > 0) {
        /** arc des montant en cours (vert) */
        degree1 = this.getDegreeFromAmount(total, montantEnCours);

        path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path1.setAttribute('d', this.describeArc(200, 0, degree1));
        path1.classList.toggle('vert');
        el.appendChild(path1);
      }
      if (montantAPayer > 0) {
        /** arc des montant à payer (jaune) */
        degree2 = this.getDegreeFromAmount(total, montantAPayer);

        if (montantEnCours > 0) {
          point2 = degree1 + degree2; /** point de destination */
          path2 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path2.setAttribute(
            'd',
            this.describeArc(200, degree1, degree2 + degree1)
          );
          path2.classList.toggle('jaune');
          el.appendChild(path2);
        } else {
          point2 = degree2;
          path2 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path2.setAttribute('d', this.describeArc(200, 0, degree2));
          path2.classList.toggle('jaune');
          el.appendChild(path2);
        }
      }
      if (montantEnRetard > 0) {
        /** arc des montant en retard de paiement (rouge) */
        degree3 = this.getDegreeFromAmount(total, montantEnRetard);

        if (montantEnCours > 0 && montantAPayer > 0) {
          point3 = degree1 + degree2 + degree3;
          path3 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path3.setAttribute(
            'd',
            this.describeArc(
              200,
              degree2 + degree1,
              degree3 + degree1 + degree2
            )
          );
          path3.classList.toggle('rouge');
          el.appendChild(path3);
        } else if (montantAPayer > 0) {
          point3 = degree2 + degree3;
          path3 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path3.setAttribute(
            'd',
            this.describeArc(200, degree2, degree3 + degree2)
          );
          path3.classList.toggle('rouge');
          el.appendChild(path3);
        } else if (montantEnCours > 0) {
          point3 = degree1 + degree3;
          path3 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path3.setAttribute(
            'd',
            this.describeArc(200, degree1, degree3 + degree1)
          );
          path3.classList.toggle('rouge');
          el.appendChild(path3);
        } else {
          path3 = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
          );
          path3.setAttribute('d', this.describeArc(200, 0, degree3));
          path3.classList.toggle('rouge');
          el.appendChild(path3);
        }
      }
      if (degree1 !== undefined) {
        /** rajouter un point vert au dessus du jaune */
        const path = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.setAttribute('d', this.describeArc(200, degree1, degree1));
        path.classList.toggle('vert');
        path.classList.toggle('point');

        el.appendChild(path);

        const attrib = path1.getAttribute('d').split(' ');
        this.getClock(el, attrib[9], attrib[10]);
      }
      if (degree2 !== undefined) {
        /** rajouter un point jaune au dessus du rouge */
        const path = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.setAttribute('d', this.describeArc(200, point2, point2));
        path.classList.toggle('jaune');
        path.classList.toggle('point');
        el.appendChild(path);

        const attrib = path2.getAttribute('d').split(' ');
        this.getEuro(el, attrib[9], attrib[10]);
      }
      if (degree3 !== undefined) {
        const path = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.setAttribute('d', this.describeArc(200, point3, point3));
        path.classList.toggle('rouge');
        el.appendChild(path);

        const attrib = path3.getAttribute('d').split(' ');
        this.getWarning(el, attrib[9], attrib[10]);
      }
    },
    /** Calculer l'angle en fonction du montant */
    getDegreeFromAmount(total, amount) {
      /** Il faut tronquer la valeur obtenue en faisant le parseInt pour pouvoir gérer les résultats décimaux en js */
      let percent = parseInt(((amount * 100) / total) * 100) / 100;
      if (percent === 100) {
        percent = 99.5;
      }
      return percent * 3.6;
    },
    /** Créer le logo horloge en partant du point d'arrivée d'un montant */
    getClock(el, coordX, coordY) {
      const clock = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const d = [
        'M',
        coordX - 20,
        coordY,
        'a',
        20,
        20,
        0,
        0,
        0,
        40,
        0,
        'a',
        20,
        20,
        0,
        0,
        0,
        -40,
        0,
        'm',
        20,
        0,
        'v',
        -10,
        'm',
        -2,
        12,
        'h',
        11
      ].join(' ');
      clock.setAttribute('d', d);
      clock.classList.toggle('clock');
      el.appendChild(clock);
    },

    /** Créer le logo euro en partant du point d'arrivée d'un montant */
    getEuro(el, coordX, coordY) {
      const clock = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const d = [
        'M',
        coordX,
        coordY,
        'm',
        0,
        -17,
        'h',
        -17.5,
        'v',
        30,
        'h',
        35,
        'v',
        -30,
        'z',
        'm',
        -6,
        15,
        'h',
        10,
        'm',
        -1,
        -5,
        'a',
        4,
        6,
        0,
        1,
        0,
        1,
        10
      ].join(' ');
      clock.setAttribute('d', d);
      clock.classList.toggle('euro');
      el.appendChild(clock);
    },

    /** Créer le logo warning en partant du point d'arrivée d'un montant */
    getWarning(el, coordX, coordY) {
      const clock = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      const d = [
        'M',
        coordX,
        coordY,
        'm',
        0,
        -13,
        'l',
        20,
        30,
        'h',
        -40,
        'z',
        'm',
        0,
        10,
        'v',
        10,
        'm',
        0,
        3,
        'v',
        3
      ].join(' ');

      clock.setAttribute('d', d);
      clock.classList.toggle('warning');
      el.appendChild(clock);
    }
  }
};
</script>

<style lang="scss">
#paiement-counter {
  path {
    fill: none;
    stroke-linecap: round;
  }
  circle.center {
    fill: #fff;
    stroke: 1px;
    stroke-opacity: 0.4;
  }
  path.vert {
    stroke: #00ac8c;
    stroke-width: 65;
  }
  path.rouge {
    stroke: #e1000f;
    stroke-width: 65;
  }
  path.jaune {
    stroke: #ff9940;
    stroke-width: 65;
  }
  path.point {
    stroke-width: 66;
  }
  path.clock {
    fill: #fff;
    stroke: #00ac8c;
    stroke-width: 4;
    stroke-linecap: butt;
  }
  path.warning {
    fill: #fff;
    stroke: #e1000f;
    stroke-width: 4;
    stroke-linecap: butt;
  }
  path.euro {
    fill: #fff;
    stroke: #ff9940;
    stroke-width: 4;
    stroke-linecap: butt;
  }
}
.circle {
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  margin-left: -25%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
p {
  font-size: 1em;
  padding: 0;
  margin: 0;
  &.montant-value {
    font-size: 24px;
    font-weight: bold;
    color: #1e1e1e;
  }
  &.montant-header {
    color: #6a6a6a;
    margin-bottom: -5px;
  }
}
</style>
