<template>
  <div class="detail">
    <ul>
      <li class="mt-4">
        <div class="container">
          <div>
            <img
              class="icone-nombre"
              src="../assets/img/money-euro-box-fill.svg"
              alt="Montant total à date des sommes à payer avant la date limite"
            />
          </div>
          <div>
            <span class="tag-name text-gray" id="indic_pai_payer">À payer</span>
            <div class="sr-only">
              {{ $filters.numberSpacedRgaa(montantRestantAPayerCumul) }}
            </div>
            <div class="tag-price" aria-hidden="true">
              {{ $filters.numberSpaced(montantRestantAPayerCumul) }}
            </div>
          </div>
          <div class="icone-information">
            <Info
              href="#"
              data="Sommes que vous devez payer auprès de l’administration, avant la date limite de paiement."
              class="fr-fi-information-line ml-2 info a-info"
            />
          </div>
        </div>
      </li>
      <li class="mt-4">
        <div class="container">
          <div>
            <img
              class="icone-nombre"
              src="../assets/img/alert-fill.svg"
              alt="Montant total à date des sommes en retard de paiement"
            />
          </div>
          <div>
            <span class="tag-name text-gray" id="indic_pai_regul"
              >À payer (en retard)</span
            >
            <div class="sr-only">
              {{
                $filters.numberSpacedRgaa(
                  montantRestantAPayerCumulAPayerEnRetard
                )
              }}
            </div>
            <div class="tag-price" aria-hidden="true">
              {{
                $filters.numberSpaced(montantRestantAPayerCumulAPayerEnRetard)
              }}
            </div>
          </div>
          <div class="icone-information">
            <Info
              href="#"
              cssClass="fr-fi-information-line ml-2 info a-info"
              data="Sommes que vous n’avez pas payées à l’administration dans les délais réglementaires. Vous devez payer cette somme dès que possible."
            />
          </div>
        </div>
      </li>
      <li class="mt-4">
        <div class="container">
          <div>
            <img
              class="icone-nombre"
              src="../assets/img/time-fill.svg"
              alt="Montant total à date des sommes payées en cours de traitement"
            />
          </div>
          <div>
            <span class="tag-name text-gray" id="indic_pai_trait"
              >En cours de traitement</span
            >
            <div class="sr-only">
              {{ $filters.numberSpacedRgaa(montantRestantAPayerCumulEnCours) }}
            </div>
            <div class="tag-price" aria-hidden="true">
              {{ $filters.numberSpaced(montantRestantAPayerCumulEnCours) }}
            </div>
          </div>
          <div class="icone-information">
            <Info
              href="#"
              cssClass="fr-fi-information-line ml-2 info a-info"
              data="Sommes pour lesquelles vous avez déjà validé le paiement, mais non encore débitées de votre compte en banque. Vous n’avez aucune action à réaliser."
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Info from '../shared/Info';
export default {
  name: 'paiement',
  components: {
    Info
  },
  props: {
    informations: Array
  },
  computed: {
    montantRestantAPayerCumul() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'A_PAYER'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    },
    montantRestantAPayerCumulAPayerEnRetard() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'A_REGULARISER'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    },
    montantRestantAPayerCumulEnCours() {
      if (this.informations && this.informations.length > 0) {
        const result = this.informations.filter(
          (a) => a.statut === 'EN_COURS_DE_TRAITEMENT'
        )[0];
        return result && result.montantEtatDesPaiements
          ? result.montantEtatDesPaiements
          : 0;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
html.touch div {
  width: 480px;
}

html.no-touch div {
  width: auto;
}

ul {
  list-style-type: none;
  padding-left: 0rem;
}

.tag-name {
  font-size: 14px !important;
  margin-left: 10px;
  margin-top: 1px;
}
.bg-light {
  background: #e7e7e7 !important;
}
.tag-price {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-left: 10px;
  margin-bottom: 10px;
}
.fr-fi-information-line::before {
  vertical-align: middle;
}
.detail {
  padding-top: 15px;
}
.float-r {
  float: right;
}

.icone-nombre {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative;
  top: 13px;

  z-index: -1;
}
.container {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
}

.icone-information {
  align-self: center;
  margin-left: auto;
}

.nb-creances {
  margin-left: -10px;
  width: 24px;
  height: 19px;
}
</style>
