<template>
  <div class="col-12 relative">
    <div class="fr-container">
      <div
        class="legende d-flex position-absolute"
        v-if="checkHabilitationEcheancier"
      >
        <h1 class="sr-only">Légende des icônes</h1>
        <img
          src="../assets/img/icon-user.svg"
          class="mx-2 mt-1"
          alt="Petite icône avec une silhouette d'homme couleur vert"
        />
        Social
        <img
          src="../assets/img/icon-chart.svg"
          class="mx-2 mt-1"
          alt="Petite icône représentant un diagramme camenbert gris"
        />
        Fiscal
      </div>
      <div class="d-flex justify-content-center relative">
        <div class="title">
          <h2 class="text-center mt-4 fs-b rs-fs">Échéancier déclaratif</h2>
          <Info
            v-if="checkHabilitationEcheancier"
            href="#"
            cssClass="fr-fi-information-line ml-3 info a-info"
            :data="disclaimer"
          />
        </div>
      </div>
      <p
        class="text-center ml-n4"
        v-if="checkHabilitationEcheancier && (dateDebut || dateFin)"
      >
        Période du {{ $filters.dateFormat(dateDebut) }} au
        {{ $filters.dateFormat(dateFin) }}
      </p>
      <div
        v-if="window.width <= 760 && checkHabilitationEcheancier"
        class="legende-mobile d-flex"
      >
        <h1 class="sr-only">Légende des icônes</h1>
        <img
          src="../assets/img/icon-user.svg"
          alt="Petite icône avec une silhouette d'homme couleur vert"
        />
        Social
        <img
          src="../assets/img/icon-chart.svg"
          class="ml-2_1"
          alt="Petite icône représentant un diagramme camenbert gris"
        />
        Fiscal
      </div>
      <ul
        v-if="checkHabilitationEcheancier"
        class="container timeline"
        :class="{ 'mt-5': !noData }"
      >
        <div
          v-for="(line, index) in data"
          :key="`li_month${index}-${line.month}`"
        >
          <li>
            <div
              class="month fs-b fs-925"
              :key="`${index}-${line.month}`"
              :class="{
                active:
                  line.month ===
                  new Date().toLocaleDateString('fr-FR', {
                    month: 'short'
                  })
              }"
            >
              <p
                class="sr-only"
                v-if="
                  line.month ===
                  new Date().toLocaleDateString('fr-FR', {
                    month: 'short'
                  })
                "
              >
                mois actif
              </p>
              <p>{{ line.month.toUpperCase() }}</p>
            </div>
            <ul :key="`ul_month${index}-${line.month}`">
              <div
                v-for="(decla, dIndex) in line.data"
                :key="`li_decla${index}-${dIndex}`"
              >
                <li class="d-flex">
                  <div
                    class="date text-center text-gray-700 py-3 pr-4 rs-fs-14"
                    :class="{
                      'mt-2': dIndex > 0,
                      'mt-3': dIndex == 0,
                      premier: dIndex == 0
                    }"
                  >
                    <p>{{ decla.dateLimiteDepot }}</p>
                    <div class="dot-background"></div>
                    <img
                      v-if="decla.administration !== 'ACOSS'"
                      src="../assets/img/icon-chart.svg"
                      class="dot"
                      alt="Petite icône représentant un diagramme camenbert gris"
                    />
                    <img
                      v-else
                      src="../assets/img/icon-user.svg"
                      class="dot"
                      alt="Petite icône avec une silhouette d'homme couleur vert"
                    />
                  </div>
                  <div
                    class="declaration py-2"
                    :class="{
                      'pt-3': dIndex > 0,
                      'pt-4': dIndex == 0,
                      'pb-4': dIndex === line.data.length - 1,
                      premier: dIndex == 0
                    }"
                  >
                    <div
                      class="declaration-bloc ml-5 pl-4 text-gray rs-declaration-bloc rs-fs-14"
                    >
                      <b class="color">{{ decla.libelleDecla }} </b>
                      <span class="hide-el">|</span>
                      {{ decla.complementDecla }}
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </li>
        </div>
        <div class="d-table m-auto no-data" v-if="noData">
          <p class="h4"><strong>Aucune échéance connue</strong></p>
          <img
            src="../assets/img/non-dispo.svg"
            alt="image représentant un pc portable avec un point d'exclamation et une barre de chargement à l'intérieur "
          />
        </div>
      </ul>
      <div v-else>
        <NonHabilite
          :avecPadding="false"
          :avecRetourTdb="false"
          classTexte="no-service col-md-8 col-12"
          classImage="col-md-4 col-12"
        />
      </div>
    </div>
    <p
      class="disclaimer text-center mt-3 rs-fs-14"
      v-if="checkHabilitationEcheancier && !noData"
    >
      {{ disclaimer }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Info from '../shared/Info';
import NonHabilite from '../views/NonHabilite';

export default {
  components: {
    Info,
    NonHabilite
  },
  props: {
    declarationData: {
      type: Object,
      default: () => {}
    },
    infoService: Boolean //check si on a des erreurs dans la réponse du service
  },
  data() {
    return {
      dateDebut: '',
      dateFin: '',
      data: {},
      disclaimer:
        'Ces informations sont données à titre indicatif et ne sont pas nécessairement exhaustives.',
      window: {
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    checkHabilitationEcheancier() {
      return this.codeAffichages['bloc-echeancier-tdb-global'];
    },
    ...mapGetters(['siren', 'comptesRattachees', 'codeAffichages']),
    noData() {
      return (
        !(
          this.declarationData &&
          this.declarationData.resultat &&
          this.declarationData.resultat.calendrierDeclaratif &&
          this.declarationData.resultat.calendrierDeclaratif
            .echeancesDeclaratives &&
          this.declarationData.resultat.calendrierDeclaratif
            .echeancesDeclaratives.length > 0
        ) && !this.infoService
      );
    }
    // On enlève 1% de la largeur de l'échéancier pour un pas afficher la barre de scroll
    // echeancierStyle() {
    //   return (
    //     'width: ' +
    //     (window.innerWidth - Math.round(window.innerWidth / 100)) +
    //     'px; left: 50%; transform: translateX(-50%);'
    //   );
    // }
  },
  async mounted() {
    // IMPORTANT : this.codeAffichages['bloc-echeancier-tdb-global'] est calculé depuis un autre appel asynchrone (checkAll) du composant WidgetMessagerie
    // Il faut attendre que cette valeur soit calculé avant d'afficher ce composant (WidgetEcheancierRefonte) et c'est faisable au niveau de son parent (voir chargementCodeAffichages dans TableauDeBord.vue)

    if (this.checkHabilitationEcheancier) {
      const data = this.declarationData;

      let {
        calendrierDeclaratif: { echeancesDeclaratives, dateDebut, dateFin }
      } = data.resultat
        ? data.resultat
        : {
            calendrierDeclaratif: {
              echeancesDeclaratives: [],
              dateDebut: '',
              dateFin: ''
            }
          }; // anticiper un null

      this.data = [
        ...new Set(
          echeancesDeclaratives
            .map(({ dateLimiteDepot }) =>
              new Date(dateLimiteDepot).toLocaleDateString('fr-FR', {
                month: 'short'
              })
            )
            .reverse()
        )
      ].map((month) => ({
        month,
        data: echeancesDeclaratives
          .filter(
            ({ dateLimiteDepot }) =>
              new Date(dateLimiteDepot).toLocaleDateString('fr-FR', {
                month: 'short'
              }) === month
          )
          .map(
            ({
              dateLimiteDepot,
              libelleDecla,
              complementDecla,
              administration
            }) => ({
              dateLimiteDepot: new Date(dateLimiteDepot).toLocaleDateString(
                'fr-FR'
              ),
              libelleDecla,
              complementDecla,
              administration
            })
          )
          .reverse()
      }));

      this.dateDebut = dateDebut;
      this.dateFin = dateFin;
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: #1e1e1e;
  font-size: 40px;
}
ul {
  list-style-type: none;
  padding-left: 0rem;
}
.timeline {
  width: 100%;
}
.month {
  background: #e9e9e9;
  width: 80px;
  height: 55px;
  text-align: center;
  margin-left: 70px;
  &.active {
    background: $base-color;
    color: #fff;
    text-decoration: underline;
  }
}
.month p {
  line-height: 55px;
}
.date {
  height: 45px;
  position: relative;
  padding: 12px 0;
}
.date p {
  line-height: 27px;
  width: 90px;
}
.declaration {
  width: 100%;
  border-left: 1px solid gray;
}
.dot {
  position: absolute;
  background: #f9f8f6;
  height: 14px;
  width: 18px;
  top: 24px;
  right: -8px;
  transform: scale(1.2);
}
.dot.purple::before {
  background: #484d7a;
}
.dot-background {
  background: #f9f8f6;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: 20px;
  right: -12px;
  content: '';
  display: inline-block;
  position: absolute;
}
.declaration-bloc {
  background: white;
  border: 1px solid #d8d8d8;
  width: 80%;
  max-width: 100%;
  padding: 12px 0;
}
.info::before {
  position: relative;
  top: 38px;
}
.col-12 {
  background: #f9f8f6;
}
b {
  color: #1e1e1e !important;
}
.title {
  flex: 2 1 0%;
  display: flex;
  justify-content: center;
}
.legende {
  right: 30px;
  margin-left: auto;
  width: fit-content;
  position: relative;
  top: 60px;
}

.disclaimer {
  background-color: white;
  padding: 1.875rem;
  color: #6a6a6a;
}

.fr-fi-information-line::before {
  vertical-align: middle;
}

@media only screen and (max-width: 850px) {
  .legende {
    display: none !important;
  }
  .legende-mobile {
    justify-content: center;
  }
  .info::before {
    top: 26px;
  }
  .rs-fs {
    font-size: 22px;
  }
  .rs-fs-14 {
    font-size: 14px;
  }
  .rs-declaration-bloc {
    padding-left: 0.5rem !important;
  }
  .hide-el {
    display: none;
  }
  .month {
    margin-left: 60px;
  }
  .date > p {
    position: relative;
    top: -15px;
    left: 60px;
    background-color: #f9f8f6;
  }
  .date:not(.premier) {
    margin-top: 0rem !important;
  }
  .declaration:not(.premier) {
    padding-top: 0rem !important;
    margin-top: 0.5rem;
  }
  .dot {
    top: 28px;
  }
}
.no-data {
  p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  img {
    width: 80%;
  }
  @media only screen and (max-width: 760px) {
    margin-top: 3rem !important;
    padding-bottom: 1rem !important;
  }
}
</style>
