<template>
  <div
    class="col-sm-12 col-12 bloc-wrapper"
    :class="{ first: header === 'À régulariser' }"
  >
    <div class="d-flex justify-content-between header">
      <div :class="{ 'd-flex': window.width >= 990 }">
        <span
          :id="idbloc"
          class="badge rounded-pill count"
          :style="`border: 1px solid ${color} !important;`"
          >{{ count }}</span
        >
        <span id="nbEchDepasses" class="fs-875 label-header">{{ header }}</span>
        <span class="future" v-if="header === 'À régulariser'">
          (échéance dépassée)
        </span>
        <span class="future" v-if="header === 'À envoyer'">
          (sous 30 jours)</span
        >
      </div>
      <Info
        href="#"
        :data="info"
        cssClass="fr-fi-information-line ml-3 info a-info"
      />
    </div>
    <div v-if="isMobileDevice()" class="pointer" @click="showDec = !showDec">
      <span v-if="!showDec" class="declarion-lab"
        >Afficher les déclarations</span
      >
      <span v-if="showDec" class="declarion-lab fs-b"
        >Masquer les déclarations</span
      >
      <i v-if="!showDec" class="ri-add-fill ic-position"></i>
      <i v-if="showDec" class="ri-subtract-fill ic-position"></i>
    </div>
    <div v-if="showDec || !isMobileDevice()" class="lines">
      <ul>
        <li
          class="fs-875"
          v-for="(line, index) in declarations.slice(0, 3)"
          :key="index"
        >
          {{ line.split('\n')[0] }}
          <b v-if="line.split('\n')[1]">{{ '\n' + line.split('\n')[1] }}</b>
        </li>
      </ul>
      <p class="declarations-left fs-875" v-if="declarations.length > 3">
        ... +{{ declarations.length - 3 }}
      </p>
      <!-- <p
        class="declaration-line"
        v-for="(line, index) in declarations.slice(0, 3)"
        :key="index"
      >
        {{ line }}
      </p>
      <p class="declarations-left" v-if="declarations.length > 3">
        ... +{{ declarations.length - 3 }}
      </p> -->
    </div>
  </div>
</template>
<script>
import Info from '../shared/Info';
import { isMobileDevice } from '../utils/methodsUtils';
export default {
  name: 'DeclarationBloc',
  components: {
    Info
  },
  props: {
    header: String,
    count: Number,
    color: String,
    declarations: Array,
    info: String,
    showDec: Boolean,
    idbloc: String
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  mounted() {},
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    isMobileDevice() {
      return isMobileDevice();
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss" scoped>
.bloc-wrapper {
  // border: 1px solid #dddddd;
  background-color: #fff;
  // height: 158px;
  padding: 12px;
  // width: 48%;
  margin: 0;
  margin-left: 8px;
  &.first {
    border-right: 1px solid #f0f0f0;
  }
}
p {
  font-weight: bold;
  margin-left: 7px;
  margin: 5px 0 0 7px;
}
span.future {
  color: #383838;
  font-weight: 100;
  font-size: 11px;
  margin-left: 7px;
  @media (max-width: 990px) {
    margin-left: 2px;
  }
}
.count {
  height: 26px;
  line-height: 14px;
  color: #1e1e1e;
  width: 33px;
  font-size: 14px;
  margin-top: 0.5rem;
}
.fr-fi-information-line {
  margin-top: 3px;
  transition: 0.1s;
}
.fr-fi-information-line::before {
  vertical-align: text-top;
}
.lines {
  margin-top: 12px;
  min-height: 120px;
}
.declaration-line {
  color: #8d8c8b;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-left: 0;
}
.declarations-left {
  text-align: left;
  margin-top: -5px;
}
ul {
  padding-left: 20px;
}
li {
  white-space: pre-line;
}
.header {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .bloc-wrapper {
    width: 48%;
  }
}
b {
  color: #1e1e1e !important;
}
.declarion-lab {
  margin-left: 1rem;
  font-size: 14px;
}
.fs-b {
  font-weight: bold;
}
.ic-position {
  float: right;
}
.label-header {
  margin-left: 5px;
  margin-top: 4px;
}
.pd-left-3 {
  padding-left: 3px;
}
</style>
