<template>
  <div class="col-sm-12 col-md-8 col-12 declaration-wrapper" id="w-tdb-decla">
    <div class="d-flex justify-content-between declaration-header">
      <div class="fr-grid-row">
        <div class="d-flex rs-width" v-if="checkHabilitationDeclarations">
          <h2 class="fs-b rs-fs" id="indic_Decl_total">
            <span class="badge rounded-pill declaration-count">{{
              totalDeclarations
            }}</span>
            Déclaration(s) sociale(s) ou fiscale(s)
          </h2>
        </div>
        <div v-else>
          <h2 class="fs-b rs-fs">Déclaration(s) sociale(s) ou fiscale(s)</h2>
        </div>

        <a
          v-if="checkHabilitationDeclarations"
          class="fr-link p-0 rs-width m-lf-2 tbd2-under no-box-shadow"
          href="/ms/declarations"
          id="tbd_02"
          @click="tagToutesLesDeclarations()"
          title="Toutes les déclarations"
          >Toutes les déclarations <i class="ri-arrow-right-line ml-1"></i
        ></a>
      </div>
    </div>
    <div class="row px-2" v-if="checkHabilitationDeclarations">
      <DeclarationBloc
        :count="toRegularize.length"
        id="indic_decl_regul"
        header="À régulariser"
        color="#e1000f"
        :declarations="toRegularize"
        :info="infos[0]"
        idbloc="nb_regulariser"
        v-if="!noData"
      />
      <DeclarationBloc
        :count="toSent.length"
        header="À envoyer"
        id="Indic_decl_envoyer"
        color="#00ac8c"
        :declarations="toSent"
        :info="infos[1]"
        idbloc="nb_envoyer"
        v-if="!noData"
      />
      <div class="d-table no-data" v-if="noData">
        <p class="h4"><strong>Aucune déclaration connue</strong></p>
        <img
          src="../assets/img/non-dispo.svg"
          alt="image avec pc avec un point d'exclamation dedans"
        />
      </div>
    </div>
    <NonHabilite
      class="row px-2"
      :avecPadding="false"
      :avecRetourTdb="false"
      v-if="!checkHabilitationDeclarations"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DeclarationBloc from './DeclarationBloc';
import NonHabilite from '../views/NonHabilite';
import { sendTag } from '../utils/methodsUtils';

import { EVENT_BUS_ERGO_INCREASE_DECREASE_FONT } from '../constantes';

const infos = [
  'Déclarations non déposées dans les temps auprès de l’administration. Vous devez déposer une déclaration sans délai.',
  'Déclarations à déposer auprès de l’administration. Vous devez déposer une déclaration avant la date limite de dépôt.',
  'Prochaines déclarations à déposer auprès de l’administration. Cette information est donnée à titre d’information, vous ne pouvez pas encore déposer ces déclarations'
];

export default {
  name: 'Declaration',
  components: {
    DeclarationBloc,
    NonHabilite
  },
  props: {
    declarationData: {
      type: Object,
      default: () => {}
    },
    infoService: Boolean //check si on a des erreurs dans la réponse du service
  },
  data() {
    return {
      totalDeclarations: 0,
      toRegularize: [],
      toSent: [],
      infos
    };
  },
  computed: {
    checkHabilitationDeclarations() {
      return this.codeAffichages['bloc-declarations-tdb-global'];
    },
    noData() {
      return this.totalDeclarations == 0 && !this.infoService;
    },

    ...mapGetters(['siren', 'comptesRattachees', 'codeAffichages'])
  },
  methods: {
    setClassOfTBD2() {
      const elem = document.getElementById('tbd_02');
      if (elem) {
        const fontSize = parseFloat(
          window.getComputedStyle(elem, null).getPropertyValue('font-size')
        );
        if (fontSize < 17 && elem.classList.contains('tbd2-under')) {
          elem.classList.remove('tbd2-under');
        } else if (!elem.classList.contains('tbd2-under')) {
          elem.classList.add('tbd2-under');
        }
      }
    },
    tagToutesLesDeclarations() {
      sendTag(
        'toutes_les_declarations',
        'tbd_global_synthese_declarations',
        '',
        ''
      );
    }
  },

  async mounted() {
    this.emitter.on(EVENT_BUS_ERGO_INCREASE_DECREASE_FONT, () => {
      this.setClassOfTBD2();
    });
    // IMPORTANT : this.codeAffichages['bloc-declarations'] est calculé depuis un autre appel asynchrone (checkAll) du composant WidgetMessagerie
    // Il faut attendre que cette valeur soit calculer avant d'afficher ce composant (WidgetDeclaration) et c'est faisable au niveau de son parent (voir chargementCodeAffichages dans TableauDeBord.vue)
    if (this.checkHabilitationDeclarations) {
      const data = this.declarationData;

      let {
        syntheseDeclarations: { totalDeclarations, declarationCumulStatuts }
      } = data.resultat
        ? data.resultat
        : {
            syntheseDeclarations: {
              totalDeclarations: 0,
              declarationCumulStatuts: []
            }
          }; // anticiper un null

      const toRegularize = declarationCumulStatuts.filter(
        ({ statut }) => statut === 'A_REGULARISER'
      );
      const toSent = declarationCumulStatuts.filter(
        ({ statut }) => statut === 'A_ENVOYER'
      );

      this.toRegularize = toRegularize.length
        ? toRegularize[0].listeDecla.map(({ libelleDecla }) => libelleDecla)
        : [];
      this.toSent = toSent.length
        ? toSent[0].listeDecla.map(({ libelleDecla }) => libelleDecla)
        : [];
      this.totalDeclarations = totalDeclarations;
    }
  }
};
</script>
<style lang="scss" scoped>
.declaration-wrapper {
  font-family: 'Marianne';
  border: 1px solid #dddddd;
  height: min-content;
  background-color: #fefefe;
  // padding-bottom: 20px;
}
.declaration-header {
  padding: 15px 20px 0 14px;
}
.declaration-count {
  font-size: 1.1rem;
  width: 38px;
  position: relative;
  bottom: 0px;
  line-height: 18px;
  height: 32px;
  border: 1px solid #000;
  color: #000;
}

h2 {
  color: #1e1e1e !important;
  margin-left: 10px;
  /* RGAA : le titre "" doit être un h2 : 
  on lui donne l'apparence du h4 pour ne rien changer à l'affichage */
  font-size: calc(1.275rem + 0.3vw);
  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
}

a {
  color: $base-color !important;
}
.fr-link::before {
  line-height: unset;
}
#w-tdb-decla {
  height: 22.177rem;
}
@media only screen and (max-width: 760px) {
  .rs-width {
    width: 100%;
  }
  .rs-fs {
    font-size: 22px;
  }
  #w-tdb-decla {
    height: 100%;
  }
  #tbd_02 {
    margin: auto;
    width: max-content;
  }
  #indic_decl_regul {
    border-right-color: transparent;
  }
}
.m-lf-2 {
  margin-left: 2rem;
}
.pd-left-3 {
  padding-left: 3px;
}

a:focus {
  border-radius: 0;
}

.no-box-shadow {
  box-shadow: none;
}
@media only screen and (min-width: 760px) {
  .tbd2-under {
    background-position: var(--underline-x) calc(100% - 1em),
      var(--underline-x) calc(100% - 1em);
  }
}
.no-data {
  p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  img {
    width: 80%;
  }
  @media only screen and (max-width: 760px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
