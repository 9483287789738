<template>
  <div class="row pb-5 pt-4">
    <div class="row g-0">
      <div
        class="row col-12 col-sm-12 col-md-8 bloc-etat-paiements"
        v-if="checkHabilitationPaiements"
      >
        <h2 class="text-center mt-4 fs-b rs-fs">
          État des paiements à date
          <Info
            href="#"
            cssClass="fr-fi-information-line ml-3 info a-info"
            data="Ces informations sont données à titre indicatif et ne sont pas nécessairement exhaustives."
          />
        </h2>
        <div class="col-12 col-sm-6 col-md-6" id="indic_paie_total">
          <Counter v-if="loaded" :total="total()" :informations="data" />
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <Details :informations="data" />
        </div>
        <div class="row text-center" v-if="codeAffichages['bloc-ti-acoss']">
          <div class="col-12 m-t-1">
            <a
              id="tbd_01"
              class="fr-btn fr-btn--lg bouton-bleu payer"
              href="/ms/paiements"
              title="Accéder aux paiements"
              @click="tagAccederAuxPaiements()"
            >
              <span class="acceder-paiements" />
              Accéder aux paiements</a
            >
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-4 g-4 align-self-center"
        v-if="checkHabilitationPaiements && !codeAffichages['bloc-ti-acoss']"
      >
        <a
          id="tbd_01"
          class="fr-btn fr-btn--lg bouton-bleu"
          href="/ms/paiements"
          aria-label="Boutton pour accéder aux paiements"
          @click="tagAccederAuxPaiements()"
        >
          <span class="acceder-paiements" /> Accéder aux paiements</a
        >
      </div>
      <div
        v-if="!checkHabilitationPaiements"
        class="row col-12 col-sm-12 col-md-8"
      >
        <h2 class="text-center mt-4 fs-b rs-fs">
          État des paiements à date
          <Info
            href="#"
            cssClass="fr-fi-information-line ml-3 info a-info"
            data="Ces informations sont données à titre indicatif et ne sont pas nécessairement exhaustives."
          />
        </h2>
        <NonHabilite :avecPadding="false" :avecRetourTdb="false" />
      </div>

      <div
        class="col-12 col-sm-12 col-md-4 g-4 bloc-travailleur"
        v-if="codeAffichages['bloc-ti-acoss']"
      >
        <blocTi />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getCreancesCumuls } from '../services/CreancesService';
import Counter from '../components/Counter';
import Details from '../components/PaiementsDetails';
import BlocTi from '../components/BlocTi';
import Info from '../shared/Info';
import NonHabilite from '../views/NonHabilite';
import {
  technical,
  gererErreur,
  GENERIC_ERROR_MESSAGE
} from '../utils/erreurUtils';
import { sendTag } from '../utils/methodsUtils';

export default {
  components: {
    Counter,
    Details,
    BlocTi,
    Info,
    NonHabilite
  },
  data() {
    return {
      data: [
        { montantRestantAPayerCumule: 0, nombreCreancesNonSoldees: 0 },
        { montantRestantAPayerCumule: 0, nombreCreancesNonSoldees: 0 },
        { montantRestantAPayerCumule: 0, nombreCreancesNonSoldees: 0 }
      ],
      dataLoaded: false
    };
  },

  computed: {
    checkHabilitationPaiements() {
      return this.codeAffichages['bloc-paiements'];
    },
    ...mapGetters(['codeAffichages', 'siren', 'comptesRattachees']),
    loaded() {
      return this.dataLoaded;
    }
  },
  async mounted() {
    if (this.checkHabilitationPaiements) {
      try {
        const resp = await getCreancesCumuls(this.siren);
        this.data = await resp.data.resultat;

        this.dataLoaded = true;
        if (!this.data) {
          this.data = []; // éviter un éventuel null
        }

        //erreurs?
        const errInfo = resp.data.infos;

        if (errInfo && errInfo.length > 0) {
          errInfo.forEach((e) => {
            gererErreur({
              source: 'tdb',
              messages: e.infoMessage,
              id: 'err-back-tdb-etat-paiement-creances-cumuls',
              infoType: e.infoType,
              infoCode: e.infoCode
            });
          });
        }
      } catch (error) {
        gererErreur({
          source: 'tdb',
          messages: GENERIC_ERROR_MESSAGE,
          id: 'err-front-tdb-etat-paiement-creances-cumuls',
          infoType: technical
        });
      }
    }
  },
  methods: {
    total() {
      return this.data && this.data.length > 0
        ? this.data
            .map((a) => a.montantEtatDesPaiements)
            .reduce((total, montant) => total + montant)
        : null;
    },
    tagAccederAuxPaiements() {
      sendTag('acceder_aux_paiements', 'tbd_global_synthese_paiements', '', '');
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: #1e1e1e;
  font-size: 40px;
}
.acceder-paiements {
  font-size: 20px;
  margin-left: 6px;
  margin-right: 6px;
}
h5 {
  color: $base-color;
  font-size: 18px;
}
@media (min-width: 768px) {
  .bloc-etat-paiements {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .bloc-travailleur {
    margin-left: 0rem;
  }
}

.bloc-etat-paiements div:last-child {
  margin: auto;
}
@media only screen and (max-width: 760px) {
  .rs-fs {
    font-size: 22px;
  }
  .m-t-1 {
    margin-top: 10px;
  }
  h2 {
    margin-left: 0;
  }
  .bloc-etat-paiements {
    margin-top: 1.5rem;
  }
}

#tbd_01 {
  font-weight: inherit;
}
</style>
