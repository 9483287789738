<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column height-100">
    <div class="d-flex">
      <h2 class="title h4 fs-b">Messagerie</h2>
      <div class="see-all" v-if="messagerie">
        <a
          class="fr-link color-pr p-0 no-box-shadow"
          target="_self"
          href="/ms/messagerie"
          title="Tous les messages"
          id="mes_01"
          @click="tagTousLesMessages()"
          >Tous les messages <i class="ri-arrow-right-line ml-1"></i
        ></a>
      </div>
    </div>

    <div v-if="!messagerie" class="text-center mt-4">
      <div class="fs-b mb-4 h6">Service non accessible</div>
      <p>
        <router-link
          id="rattacher-compte"
          class="fr-btn fr-btn--small fr-btn--secondary mb-2 text--small mb-2"
          :to="{ name: 'Gestion des rattachements' }"
          title="Rattacher un compte"
          >Rattacher un compte</router-link
        >
        <router-link
          id="demander-habilitations"
          class="fr-btn text--small"
          :to="{ name: 'Gestion des habilitations' }"
          title="Demander des habilitations"
          >Demander des habilitations</router-link
        >
      </p>
    </div>

    <div class="mg-bottom messages-list d-flex flex-column" v-if="messagerie">
      <div v-if="!indicateursKO" class="d-flex mt-4 notif">
        <span class="badge rounded-pill">{{
          indicateurs.nbDemandesNonlus
        }}</span>
        <span class="fs-925 ml-2">Réponses non lues</span>
      </div>
      <div v-if="!indicateursKO" class="d-flex mt-3 notif">
        <span class="badge rounded-pill">{{
          indicateurs.nbDemandesEnCours
        }}</span>
        <span class="fs-925 ml-2">Demandes en cours</span>
      </div>
    </div>

    <div class="msg-footer d-flex flex-column" v-if="messagerie">
      <p class="fs-b fs-875 text-center mb-3">
        Accès rapides pour les demandes concernant :
      </p>
      <div class="m-auto d-flex">
        <img
          src="../assets/img/icon-user.svg"
          alt="Petite icône avec une silhouette d'homme couleur vert
          "
          :class="[
            {
              'service-disabled': !accesRapideAcossAccessible
            },
            'mr-1'
          ]"
        />
        <button
          v-if="comptesRattachees.urssaf === true && accesRapideAcossAccessible"
          ref="urssaf"
          class="acces-rapide-menu bouton-bleu logo-container pointer m-p-left-0 bouton-urssaf toggle-button"
          @click.stop="showDetails('urssaf')"
          @keydown.tab="inMenu(false)"
          id="mes_02"
          title="Cliquer ici pour accéder au bloc de messagerie ACOSS"
        >
          Urssaf
          <span
            v-if="arrowUrssaf"
            class="fr-fi-arrow-up-s-line"
            aria-hidden="true"
          ></span>
          <span
            v-else
            class="fr-fi-arrow-down-s-line"
            aria-hidden="true"
          ></span>
        </button>
        <Info
          v-else-if="
            comptesRattachees.urssaf === true && !accesRapideAcossAccessible
          "
          href="#"
          class="mt-2"
          data="Pour bénéficier de ces services, vous devez demander des habilitations"
          cssClass="a-info logo-container service-disabled left-0"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Urssaf"
        />
        <Info
          v-else-if="!comptesRattachees.urssaf"
          href="#"
          class="mt-2"
          data="Pour bénéficier de ces services, vous devez rattacher un compte Urssaf sur le portail"
          cssClass="a-info logo-container service-disabled left-0"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Urssaf"
        />

        <img
          src="../assets/img/icon-chart.svg"
          alt="
          Petite icône représentant un diagramme camenbert gris
          "
          :class="[
            { 'service-disabled': !codeAffichages['bloc-messagerie-dgfip'] },
            'ml-20',
            'mr-1'
          ]"
        />
        <button
          v-if="
            comptesRattachees.dgfip === true &&
            codeAffichages['bloc-messagerie-dgfip']
          "
          ref="impots"
          class="acces-rapide-menu bouton-bleu logo-container pointer m-p-left-0 bouton-impots toggle-button"
          @click.stop="showDetails('impots')"
          @keydown.tab="inMenu(false)"
          id="mes_03"
          title="Cliquer ici pour accéder au bloc de messagerie DGFIP"
        >
          Impôts
          <span
            v-if="arrowImpots"
            class="fr-fi-arrow-up-s-line"
            aria-hidden="true"
          ></span>
          <span
            v-else
            class="fr-fi-arrow-down-s-line"
            aria-hidden="true"
          ></span>
        </button>
        <Info
          v-else-if="
            comptesRattachees.dgfip === true &&
            !codeAffichages['bloc-messagerie-dgfip']
          "
          href="#"
          class="mt-2"
          data="Pour bénéficier de ces services, vous devez demander des habilitations"
          cssClass="a-info logo-container service-disabled left-0"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Impôts"
        />
        <Info
          v-else-if="!comptesRattachees.dgfip"
          href="#"
          class="mt-2"
          data="Pour bénéficier de ces services, vous devez rattacher un compte impots.gouv.fr sur le portail"
          cssClass="a-info logo-container service-disabled left-0"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Impôts"
        />
        <img
          src="../assets/img/icon-global-line.svg"
          alt="icone représentant un globe terrestre rouge"
          :class="[
            { 'service-disabled': !codeAffichages['bloc-messagerie-dgddi'] },
            'ml-20',
            'mr-1'
          ]"
        />
        <button
          v-if="
            comptesRattachees.dgddi === true &&
            codeAffichages['bloc-messagerie-dgddi']
          "
          ref="douane"
          class="acces-rapide-menu bouton-bleu logo-container pointer m-p-left-0 bouton-douane"
          @click.stop="showDetails('douane')"
          @keydown.tab="inMenu(false)"
          @keydown.tab.exact="test($event)"
          @keydown.shift.tab.exact="Maj_Tab_Shift(dropdownActive)"
          id="mes_04"
          title="Cliquer ici pour accéder au bloc de messagerie DGDDI"
        >
          Douane
          <span
            v-if="arrowDouane"
            class="fr-fi-arrow-up-s-line"
            aria-hidden="true"
          ></span>
          <span
            v-else
            class="fr-fi-arrow-down-s-line"
            aria-hidden="true"
          ></span>
        </button>
        <Info
          v-else-if="
            comptesRattachees.dgddi === true &&
            !codeAffichages['bloc-messagerie-dgddi']
          "
          href="#"
          class="bouton-douane mt-2"
          data="Pour bénéficier de ces services, vous devez demander des habilitations"
          cssClass="a-info logo-container service-disabled left-0 border-r-none"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Douane"
        />
        <Info
          v-else-if="!comptesRattachees.dgddi"
          href="#"
          class="bouton-douane mt-2"
          data="Pour bénéficier de ces services, vous devez rattacher un compte douanier sur le portail"
          cssClass="a-info logo-container service-disabled left-0 border-r-none"
          cssClassAfter="fr-icon-arrow-down-s-line"
          dataContent="Douane"
        />
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
      v-if="
        codeAffichages['bloc-messagerie'] ||
        codeAffichages['bloc-messagerie-ti']
      "
    >
      <div
        class="dropdown fs-750"
        v-show="dropdownActive"
        v-closable="{
          exclude: ['urssaf', 'douane', 'impots'],
          handler: 'onClose'
        }"
        @mouseenter="inMenu(true)"
        @mouseleave="inMenu(false)"
      >
        <div class="indicator" :class="currentDropDownItem"></div>

        <div
          class="d-flex align-items-center dropdown-line pointer"
          :class="{
            'pb-ml-0': item.libelle === 'Accéder à toutes les demandes'
          }"
          v-for="(item, index) in currentDropDown"
          :key="index"
        >
          <div
            ref="key"
            v-if="item.typeComposant === 'MENU'"
            class="fs-b fs-875 ml-2 mt-2"
          >
            {{ item.libelle }}
          </div>
          <a
            v-if="
              item.typeComposant === 'BOUTON' &&
              item.libelle != 'Accéder à toutes les demandes'
            "
            ref="derouler"
            href="#"
            class="ml-1 pointer acces-rapide-sous-menu no-box-shadow"
            @click="dropDownAction(item, $event)"
            @keydown.shift.tab="MajTabInv(item)"
            @focusout="tabinvers(index, item)"
          >
            <i class="ri-arrow-right-line color-pr ml-1"></i>
            <span class="ml-2"></span>{{ item.libelle }}
          </a>

          <footer
            v-if="
              item.typeComposant === 'BOUTON' &&
              item.libelle === 'Accéder à toutes les demandes'
            "
            class="modal-footer-demandes"
          >
            <slot name="footer">
              <button
                ref="toutes_demandes"
                type="button"
                class="btn"
                title="Cliquer ici pour vous accéder à toutes les demandes"
                @click="dropDownAction(item, $event)"
                @keydown.tab.exact="Maj_tab($event)"
                @focusout="changerCopro()"
              >
                <i class="ri-arrow-right-line color-w mr-1"></i>
                <span class="fs-b fs-875 color-w"
                  >Accéder à toutes les demandes</span
                >
              </button>
            </slot>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Info from '../shared/Info';
import UtilsMixin from '../mixins/utils-mixin';
import { normalizeString, sendTag } from '../utils/methodsUtils';
import { getRouteNameFromPath } from '../utils/navigationUtils';
import { SOURCE_PAGE_MESSAGERIE } from '../constantes';

export default {
  mixins: [UtilsMixin],
  components: {
    Info
  },
  data() {
    return {
      temp_dropdown: '',
      currentDropDown: [],
      currentDropDownItem: false,
      dropdownActive: false,
      inContextMenu: false,
      coproSelected: '',
      arrowUrssaf: false,
      arrowImpots: false,
      arrowDouane: false,
      TabPressed: false,
      TabShiftPressed: false,
      PremierBoutton: Object,
      IndexPremierBtn: 0,
      el: 0
    };
  },
  computed: {
    ...mapGetters([
      'accesRapide',
      'indicateurs',
      'codeAffichages',
      'siren',
      'indicateursKO',
      'etablissements',
      'comptesRattachees'
    ]),
    messagerie() {
      return this.accesMessagerie(this.codeAffichages);
    },
    accesRapideAcossAccessible() {
      return (
        this.codeAffichages['bloc-messagerie-acoss'] ||
        this.codeAffichages['bloc-messagerie-ti']
      );
    }
  },
  methods: {
    ...mapActions([
      'fetchAccesRapide',
      'getEtablissementsByCategorie',
      'setRouteData'
    ]),
    onClose() {
      this.dropdownActive = false;
      this.arrowUrssaf = false;
      this.arrowImpots = false;
      this.arrowDouane = false;
    },
    test(e) {
      if (
        e.keyCode == 9 &&
        this.arrowDouane == true &&
        this.TabShiftPressed == false
      ) {
        e.preventDefault();
        document
          .getElementsByClassName('fr-fi-information-line ml-3 info a-info')[0]
          .focus();
      }
      this.inMenu(false);
    },
    MajTabInv(i) {
      if (i == this.PremierBoutton) {
        this.TabShiftPressed = true;
      }
    },
    Maj_Tab_Shift() {
      this.inMenu(false);
      document.getElementById('mes_04').focus();
    },
    Maj_tab(e) {
      if (e.keyCode == 9) {
        this.TabPressed = true;
      }
    },
    TrouverPremierBtn() {
      for (let x = 0; x < this.currentDropDown.length; x++) {
        if (this.currentDropDown[x].typeComposant == 'BOUTON') {
          this.PremierBoutton = this.currentDropDown[x];
          this.IndexPremierBtn = x;
          break;
        }
      }
    },
    TrouverLibelle() {
      for (let x = 0; x < this.$refs.derouler.length; x++) {
        if (
          this.$refs.derouler[x].textContent ===
          this.PremierBoutton.libelle + ' '
        ) {
          this.el = x;
          break;
        }
      }
    },
    tabinvers(a, i) {
      if (
        this.temp_dropdown == 'urssaf' &&
        this.TabShiftPressed &&
        i === this.PremierBoutton
      ) {
        document.getElementById('mes_02').focus();
        this.TabShiftPressed = false;
      }
      if (
        this.temp_dropdown == 'impots' &&
        this.TabShiftPressed &&
        i === this.PremierBoutton
      ) {
        document.getElementById('mes_03').focus();
        this.TabShiftPressed = false;
      }
      if (
        this.temp_dropdown == 'douane' &&
        this.TabShiftPressed &&
        i === this.PremierBoutton
      ) {
        document.getElementById('mes_04').focus();
        this.TabShiftPressed = false;
      }
    },
    changerCopro() {
      if (this.TabPressed) {
        this.inMenu(false);
        if (
          this.temp_dropdown == 'urssaf' &&
          this.codeAffichages['bloc-messagerie-dgfip']
        ) {
          this.$refs.impots.focus();
        }
        if (
          this.temp_dropdown == 'urssaf' &&
          !this.codeAffichages['bloc-messagerie-dgfip'] &&
          this.codeAffichages['bloc-messagerie-dgddi']
        ) {
          this.$refs.douane.focus();
        }
        if (
          this.temp_dropdown == 'impots' &&
          this.codeAffichages['bloc-messagerie-dgddi']
        ) {
          this.$refs.douane.focus();
        }
        this.TabPressed = false;
      }
    },
    showDetails(copro, leaving = false) {
      this.coproSelected = copro;
      let _this = this;
      if (this.check(copro)) {
        // close
        if (leaving === true) {
          setTimeout(() => {
            if (_this.check(copro)) {
              this.dropdownActive = false;
            }
          }, 120);
        } else {
          this.dropdownActive = false;
        }
      } else {
        // open
        this.temp_dropdown = copro;
        this.dropdownActive = true;
        this.currentDropDown = this.accesRapide[copro];
        this.currentDropDownItem = copro;
        setTimeout(() => {
          this.TrouverPremierBtn();
          this.TrouverLibelle();
          this.$refs.derouler[this.el].focus();
        }, 0);
      }
      if (this.dropdownActive) {
        this.arrowUrssaf = copro === 'urssaf';
        this.arrowImpots = copro === 'impots';
        this.arrowDouane = copro === 'douane';
      } else {
        this.arrowUrssaf = false;
        this.arrowImpots = false;
        this.arrowDouane = false;
      }
    },
    check(copro) {
      let _this = this;
      return (
        _this.currentDropDown == _this.accesRapide[copro] &&
        _this.dropdownActive !== false &&
        !_this.inContextMenu
      );
    },
    inMenu(b) {
      this.inContextMenu = b;
      this.dropdownActive = b;
      if (!b) {
        this.arrowUrssaf = b;
        this.arrowImpots = b;
        this.arrowDouane = b;
      }
    },
    async dropDownAction(item, event) {
      this.tagDownAction(item);
      if (event) {
        event.preventDefault();
      }
      // Ouverture de la page de messagerie et chargement de l'iframe avec une url dynamique communiquée depuis le back
      if (item.typeAction === 'IFRAME_MESSAGERIE') {
        this.$router.push({
          // TODO : [vue-router] Route with name 'message' does not exist
          //name: 'message',
          name: 'Messagerie',
          query: { url: item.cible }
        });
      } else if (item.typeAction === 'IFRAME_DGFIP') {
        const routeData = {
          source: SOURCE_PAGE_MESSAGERIE,
          uri: item.cible
        };
        this.setRouteDataAndPush(routeData, 'Téléservice');
      } else if (item.typeAction === 'MEME_ONGLET') {
        if (item.verifSiret) {
          let urlParams = new URLSearchParams(item.cible);
          if (urlParams) {
            let categorie = urlParams.get('categorie');
            if (
              categorie != undefined &&
              categorie != '' &&
              categorie != '<categorie>'
            ) {
              await this.getEtablissementsByCategorie({
                siren: this.siren,
                categorie: categorie
              });
            }
          }
          if (this.etablissements.length > 1) {
            const routeData = {
              source: SOURCE_PAGE_MESSAGERIE,
              nextStep: item.cible,
              cible: item.cible
            };
            this.setRouteDataAndPush(routeData, "Sélection de l'établissement");
          } else if (this.etablissements.length == 1) {
            let cible = item.cible.replace(
              '<siret>',
              this.etablissements[0].siret
            );
            cible = cible.replace(
              '<categorie>',
              this.etablissements[0].categorie
            );
            window.location.href = cible;
          } else {
            console.log('la liste des établissements est vide');
          }
        } else if (item.cible.charAt(0) === '/') {
          const routeName = getRouteNameFromPath(item.cible, this.$router);
          this.$router.push({ name: routeName });
        } else {
          window.open(item.cible);
        }
      } else if (item.typeAction === 'NOUVEL_ONGLET') {
        window.location.href = item.cible;
      }
    },
    escape(copro) {
      this.$refs.infobulle.blur();
      if (copro === 'urssaf') {
        this.$refs.urssaf.blur();
      }
      if (copro === 'impots') {
        this.$refs.impots.blur();
      }
      if (copro === 'douane') {
        this.$refs.douane.blur();
      }
    },
    preventEvent(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('preventEvent');
    },
    tagTousLesMessages() {
      sendTag('tous_les_messages', 'accueil_bloc_messagerie', '', '');
    },
    tagDownAction(item) {
      sendTag(
        normalizeString(item.libelle),
        'accueil_bloc_messagerie',
        this.coproSelected,
        ''
      );
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  async mounted() {
    await this.fetchAccesRapide(this.siren);
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fefefe;
  font-family: 'Marianne';
  border: 1px solid #dddddd;
  padding-top: 15px !important;
  padding: 20px;
  position: relative;
}
.title {
  float: left;
}
.badge {
  border: 1px solid #1f1f1f;
  color: #000;
  width: 2rem;
}
.msg-footer {
  margin-top: auto;
  position: relative;
}
.msg-footer::before {
  content: '';
  background: #ddd;
  position: absolute;
  top: -8px;
  left: 4%;
  height: 1px;
  width: 92%;
}
.logo-container {
  border-right: 1px solid #ddd;
  box-sizing: border-box;

  font-size: 14px;
  display: flex;
  &.bouton-urssaf {
    padding-right: 20px;
  }
  &.bouton-impots {
    padding-right: 20px;
  }
  // Enlever le bord à droite car il dépasse
  &.bouton-douane {
    border-right: 0px;
    padding-right: 0px;
  }
}
a.logo-container {
  background-size: 0%;
}
a.logo-container:hover {
  background-size: 0%;
}

.notif.active {
  color: $base-color;
  span {
    background-color: $base-color;
    border: 0;
    color: white;
  }
}
.dropdown {
  position: absolute;
  top: 100%;
  border: 1px solid #ddd;
  background: #fff;
  min-width: 130%;
  margin-left: -20px;
  z-index: 99;
}
.dropdown-line {
  min-height: 2rem;
  border-bottom: 1px solid #ddd;
  padding: 0.95rem 0;
  margin-left: 1rem;
}
.dropdown-line:hover {
  background: rgba(224, 224, 224, 0.5);
}
.indicator {
  height: 2px;
  background: $base-color;
  margin-left: 3%;
  width: 17%;

  &.impots {
    transform: translateX(155%);
  }
  &.douane {
    transform: translateX(310%);
  }
}
.m-p-left-0 {
  padding-left: 0;
  margin-left: 0;
}

.see-all {
  margin-left: auto;
  /*background-position: var(--underline-x) calc(100% - 0.5em),
    var(--underline-x) calc(100% - 0.5em);*/
}

.service-disabled {
  filter: grayscale(100%);
}
.height-100 {
  height: 100%;
}

.acces-rapide-menu {
  background-color: #fefefe;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.acces-rapide-sous-menu {
  background-color: #fefefe;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  background-size: 0%;
}
.acces-rapide-sous-menu:hover {
  background-size: 0%;
}
a:focus {
  border-radius: 0;
}

.no-box-shadow {
  box-shadow: none;
}
.ml-20 {
  margin-left: 20px;
}
.mr-1 {
  margin-right: 1px;
}
.modal-footer-demandes {
  margin-top: 1rem;
  height: 2.5rem;
  background-color: #169b62;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn:hover {
  background-color: rgb(82, 201, 118);
}
.color-w {
  color: #fefefe;
}
.pb-ml-0 {
  margin-left: 0;
  padding-bottom: 0;
}

.dropdown-toggle::after {
  display: none;
}

button.dropdown-toggle.show {
  .chevron.up {
    display: block;
  }
  .chevron.down {
    display: none;
  }
}
button.dropdown-toggle {
  .chevron.up {
    display: none;
  }
  .chevron.down {
    display: block;
  }
}

.chevron {
  padding-left: 10px;
}

.mt-2 {
  margin-top: 2px !important;
}
#demander-habilitations,
#rattacher-compte {
  font-weight: inherit;
}
.mg-bottom {
  margin-bottom: 2em;
}
</style>
