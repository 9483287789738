<template>
  <div class="block-ti p-4">
    <h2 class="h6 font-weight-bold mb-3">Travailleurs indépendants</h2>
    <!-- PCR-5804 : Critère 1.2 Chaque image de décoration est-elle correctement ignorée par les technologies d'assistance ? -->
    <img src="../assets/img/ti.svg" class="mb-3" aria-hidden="true" />
    <p class="txt text--small mb-3">{{ message }}</p>
    <p class="mb-3 h4">
      <span aria-hidden="true"
        ><strong
          >{{ $filters.numberSpacedUnavailable(montantRestantAPayerCumule) }}
        </strong></span
      >
      <span class="sr-only">
        {{ montantTiRgaa ? montantTiRgaa : 'Montant indisponible' }}</span
      >
    </p>
    <a
      v-if="detail"
      :href="detail.cible"
      class="fr-link fr-link--icon-left fr-name-tag link-color no-box-shadow"
      title="Voir le détail de mes cotisations et paiements en tant que travailleur indépendant"
    >
      <span class="ri-eye-line mr-2"></span>
      <span class="text--small">{{ detail.libelle }} </span>
    </a>
    <div class="ti-footer d-flex flex-column">
      <!--<a
        v-if="cible"
        :href="cible"
        class="rf-link rf-link--icon-left rf-name-tag link-color no-box-shadow"
        title="Voir le détail"
      >
        <span class="ri-external-link-line mr-2"></span>
        <span class="text--small">Accéder à Urssaf</span>
      </a>-->
      <a
        v-if="attestations"
        :href="attestations.cible"
        class="fr-link fr-link--icon-left fr-name-tag link-color no-box-shadow"
        title="Accéder à mes attestations URSSAF"
      >
        <span class="ri-external-link-line mr-2"></span>
        <span class="text--small">{{ attestations.libelle }} </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCreancesTi } from '../services/CreancesService';
import {
  technical,
  gererErreur,
  GENERIC_ERROR_MESSAGE
} from '../utils/erreurUtils';
import { numberSpacedRgaa } from '../filters';

export default {
  data() {
    return {
      montantRestantAPayerCumule: '',
      message: '',
      detail: {},
      attestations: {}
    };
  },
  async mounted() {
    // TODO API return actionDetail, should do a factory to be API driven behavior ?
    try {
      const resp = await getCreancesTi(this.siren);
      const data = await resp.data;
      let resultat = await data.resultat;

      if (!resultat) {
        resultat = { actionDetail: {}, actionAtestations: {} }; // éviter un éventuel null
      }
      //erreurs?
      const errInfo = resp.data.infos;

      if (errInfo && errInfo.length > 0) {
        errInfo.forEach((e) => {
          gererErreur({
            source: 'tdb',
            messages: e.infoMessage,
            id: 'err-back-tdb-bloc-ti-creances',
            infoType: e.infoType,
            infoCode: e.infoCode
          });
        });
      }

      const {
        message,
        montantRestantAPayerCumule,
        actionDetail: { cible, libelle }
      } = resultat;

      this.message = message;
      this.montantRestantAPayerCumule = montantRestantAPayerCumule;
      this.detail = { cible, libelle };
      this.attestations = resultat.actionAtestations;
    } catch (error) {
      gererErreur({
        source: 'tdb',
        messages: GENERIC_ERROR_MESSAGE,
        id: 'err-front-tdb-bloc-ti-creances',
        infoType: technical
      });
    }
  },
  computed: {
    ...mapGetters(['siren', 'comptesRattachees']),
    montantTiRgaa() {
      return numberSpacedRgaa(this.montantRestantAPayerCumule);
    }
  }
};
</script>

<style lang="scss" scoped>
.block-ti {
  border: 1px solid #ddd;
  text-align: center;
}
.fr-link {
  color: $base-color;
}
.txt {
  color: #6a6a6a;
}
a:focus {
  border-radius: 0;
}

.no-box-shadow {
  box-shadow: none;
}
.ti-footer {
  margin-top: auto;
  position: relative;
  align-items: center;
  a {
    width: max-content;
  }
}
.ti-footer::before {
  content: '';
  background: #ddd;
  position: absolute;
  height: 1px;
  width: 92%;
}
</style>
